import React from 'react'
import type { NextPage } from 'next'
import Link from 'next/link'

const NotFound: NextPage = () => {
    return (
        <main className='bg-white-0 h-screen overflow-hidden px-6 relative flex flex-col items-center justify-around'>
            <svg className='absolute top-0 left-0 scale-75 md:scale-100 origin-top-left -translate-x-1/2 lg:-translate-x-1/3' width="1207" height="602" viewBox="0 0 1207 602" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="361.5" cy="-68.5" r="669.5" stroke="#EE2323" strokeWidth="2" />
                <circle cx="521" cy="-121" r="685.5" stroke="#EE2323" strokeOpacity="0.5" />
            </svg>

            <div />
            <div className='z-10 relative flex flex-col items-center text-center'>
                <h1 className='font-display text-h200 md:text-h100 font-medium mb-6 fera-dot'>
                    Такой страницы не существует
                </h1>
                <p className='text-grayscale-150 text-p350 md:text-p100 mb-8 max-w-2xl'>
                    Скорее всего, это случилось по одной из следующих причин:<br />
                    страница переехала, страницы больше нет или вам просто нравится изучать 404 страницы.
                </p>
                <Link href='/'>
                    <span className='px-8 h-11 w-full sm:w-auto bg-red-100 flex items-center justify-center rounded-full text-white-0 text-p200 font-semibold leading-full'>Вернуться на главную</span>
                </Link>
            </div>
        </main>
    )
}

export default NotFound